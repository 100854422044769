import { motion } from "framer-motion";

const InfoBox = (props) => (
  <motion.div
    className="box"
    style={{ height: "100%" }}
    initial={{ opacity: 0, scale: 0.5 }}
    whileInView={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.6 }}
    viewport={{ once: true }}
  >
    <div className="columns is-align-items-center mb-0">
      {props.img && (
        <div className="column is-narrow pb-0 has-text-centered">
          <img src={`images/${props.img}_logo.png`} alt="" />
        </div>
      )}
      <div className="column pb-0">
        <h1 className="title is-size-4">{props.title}</h1>
        <h2 className="subtitle mb-2 is-size-6">{props.subtitle}</h2>
        <p>{props.subsubtitle}</p>
      </div>
    </div>
    {props.children && (
      <div className="columns">
        <div className="column">
          <div className="content mt-4">{props.children}</div>
        </div>
      </div>
    )}
  </motion.div>
);

export default InfoBox;
