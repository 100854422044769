import "./css/App.css";
import Loading from "./sections/Loading";
import First from "./sections/First";
import Second from "./sections/Second";
import Third from "./sections/Third";
import Fourth from "./sections/Fourth";
import Fifth from "./sections/Fifth";
import Sixth from "./sections/Sixth";
import { useCallback, useState, useEffect } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { AnimatePresence } from "framer-motion";

const App = () => {
  const [isLoading, setLoading] = useState(true);

  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);

  return (
    <AnimatePresence>
      {isLoading ? (
        <Loading key="loading" />
      ) : (
        <div key="main">
          <First />
          <Second />
          <Third />
          <Fourth />
          <Fifth />
          <Sixth />
        </div>
      )}
      <Particles
        id="tsparticles"
        init={particlesInit}
        options={{
          fullScreen: {
            enable: true,
            zIndex: -1,
          },
          particles: {
            number: {
              density: {
                enable: true,
                area: 2000,
              },
            },
            color: {
              value: "#aaaaaa",
            },
            shape: {
              type: "circle",
              polygon: {
                nb_sides: 6,
              },
            },
            opacity: {
              value: 0.4,
            },
            size: {
              value: 3,
              random: true,
            },
            links: {
              enable: true,
              distance: 200,
              color: "#aaaaaa",
              opacity: 0.4,
              width: 1,
            },
            move: {
              enable: true,
              speed: 1,
            },
          },
          detectRetina: true,
        }}
      />
    </AnimatePresence>
  );
};

export default App;
