import { motion } from "framer-motion";

const ProjectBox = (props) => (
  <motion.div
    className="box"
    initial={{ opacity: 0, scale: 0.5 }}
    whileInView={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.6 }}
    viewport={{ once: true }}
  >
    <h1 className="title is-size-4-mobile">{props.title}</h1>
    <h2 className="subtitle is-size-6-mobile">{props.subtitle}</h2>
    <div className="content">{props.children}</div>
  </motion.div>
);

export default ProjectBox;
