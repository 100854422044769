import CountUp from "../components/CountUp";
import InfoBox from "../components/InfoBox";
import NumberBox from "../components/NumberBox";

const Second = () => (
  <section className="hero is-fullheight" id="experience">
    <div className="hero-body">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-6-desktop is-8-tablet">
            <InfoBox
              title="King Saud University"
              subtitle="Software Engineering Department"
              subsubtitle="Sep 2018 - Aug 2022"
              img="ksu"
            >
              Bachelor degree of science in software engineering with an
              excellent grade & first class honors.
            </InfoBox>
          </div>
          <div className="column is-2-desktop">
            <NumberBox heading="rank (out of 36)" title="1st" />
            <NumberBox
              heading="gpa (out of 5)"
              title={<CountUp end={4.94} decimals={2} />}
            />
          </div>
        </div>
        <div className="columns is-centered">
          <div className="column is-8-desktop">
            <InfoBox
              title="Software Engineer"
              subtitle="Associate"
              subsubtitle="June 2023 - Present"
              img="takamol"
            >
              Designing and implementing full-stack software solutions at
              Takamol Holding.
            </InfoBox>
          </div>
        </div>
        <div className="columns is-centered">
          <div className="column is-4-desktop">
            <InfoBox
              title="Software Engineer"
              subtitle="Freelance"
              subsubtitle="February 2023 - April 2023"
            >
              Delivered a full-stack software project for a business.
            </InfoBox>
          </div>
          <div className="column is-4-desktop">
            <InfoBox
              title="Software Engineer"
              subtitle="Internship"
              subsubtitle="June 2022 - January 2023"
              img="stc"
            >
              Completed a seven-month coop program at stc.
            </InfoBox>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Second;
