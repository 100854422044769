import { memo, useState, useEffect } from "react";
import ReactCounUp from "react-countup";
import { useInView } from "react-intersection-observer";

// wrapper component
const CountUp = memo(({ ...props }) => {
  const [hasTriggered, setHasTriggered] = useState(false);
  const [startValue, setStartValue] = useState();
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });

  // just so that the redraw property is set to true later, May there be is a better way to do that
  const { redraw, duration, ...rest } = props;
  // only re-ready when the focus change [inView]
  useEffect(() => {
    if (hasTriggered) return;
    else if (inView) {
      setStartValue(0);
      setHasTriggered(true);
    } else setStartValue(undefined);
  }, [inView, hasTriggered]);

  return (
    <span ref={ref}>
      <ReactCounUp start={startValue} {...rest} />
    </span>
  );
});

export default CountUp;
