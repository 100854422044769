import { motion } from "framer-motion";

const NumberBox = (props) => (
  <motion.div
    className="box has-text-centered"
    initial={{ opacity: 0, scale: 0.5 }}
    whileInView={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.6 }}
    viewport={{ once: true }}
  >
    <div>
      <p className="heading">{props.heading}</p>
      <p className="title">{props.title}</p>
    </div>
  </motion.div>
);

export default NumberBox;
