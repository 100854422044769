import ProjectBox from "../components/ProjectBox";
import { motion } from "framer-motion";

const Sixth = () => {
  return (
    <section className="hero is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered is-vcentered">
            <div className="column is-3-widescreen is-4-desktop">
              <ProjectBox
                title="Arabic Programming Language"
                subtitle="Compiler & Interpreter"
              >
                <p>
                  Implemented a compiler in C (and an interpreter in Java) for a
                  general-purpose object-oriented dynamically-typed programming
                  language that has an Arabic C-like syntax.
                </p>
                <div className="is-flex is-justify-content-flex-end">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/posts/activity-7044164146874982401-ZTSV"
                    className="button is-rounded"
                  >
                    Show
                  </a>
                </div>
              </ProjectBox>
            </div>
            <div className="column is-5-widescreen is-4-desktop">
              <ProjectBox title="Tadawul Dataset" subtitle="Web Scraper">
                <p>
                  Extracted two decades worth of daily stocks (770K+ rows) from
                  Saudi Stock Exchange to build ML models.
                </p>
              </ProjectBox>
              <ProjectBox title="ChatGPT Bot" subtitle="Telegram Bot">
                <p>
                  Made a bot for friends to access ChatGPT easily via Telegram
                  without signing up with OpenAI.
                </p>
              </ProjectBox>
            </div>
          </div>
        </div>
      </div>
      <motion.div
        className="hero-foot has-text-centered has-text-weight-light mb-4"
        initial={{ opacity: 0, scale: 0.5 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.6 }}
        viewport={{ once: true }}
      >
        Designed & Developed by Naif
      </motion.div>
    </section>
  );
};

export default Sixth;
