import CountUp from "../components/CountUp";
import NumberBox from "../components/NumberBox";
import DevBox from "../components/DevBox";
import ProjectBox from "../components/ProjectBox";
import { motion } from "framer-motion";

const Third = () => (
  <section className="hero is-fullheight" id="projects">
    <div className="hero-body">
      <div className="container">
        <div className="columns is-centered is-align-items-center">
          <div className="column">
            <motion.img
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              src="images/alkashef.gif"
              alt=""
            />
          </div>
          <div className="column ">
            <ProjectBox title="Books Search Engine" subtitle="Web & Mobile App">
              <p>
                Developed a system that lets users search for books in various
                bookstores at once, with prices sorted from lowest to highest.
                Check it out at <a href="https://alkashef.org">alkashef.org</a>
              </p>
              <p>
                It's a Python program that automatically scrapes bookstores
                daily, then stores the results in a database. Queries are made
                through a Python API.
              </p>
              <p>
                I monitor the database & analyze the data through a dashboard
                that I built.
              </p>
            </ProjectBox>
            <div className="columns">
              <div className="column">
                <NumberBox
                  heading="books"
                  title={<CountUp end={30000} separator={","} />}
                />
              </div>
              <div className="column">
                <NumberBox
                  heading="bookstores"
                  title={<CountUp end={15} separator={","} />}
                />
              </div>
            </div>
            <DevBox leftImg="react" centerImg="python" rightImg="aws" />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Third;
