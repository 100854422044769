import DevBox from "../components/DevBox";
import ProjectBox from "../components/ProjectBox";
import { motion } from "framer-motion";

const Fourth = () => (
  <section className="hero is-fullheight">
    <div className="hero-body">
      <div className="container">
        <div className="columns is-centered is-align-items-center">
          <div className="column is-hidden-mobile">
            <ProjectBox title="E-Invoicing System" subtitle="Desktop App">
              <p>
                Implemented a system for a business to create e-invoices,
                analyze revenue, and measure the performance of employees.
              </p>
              <p>
                It's integrated with WhatsApp cloud to run a chatbot that
                enables the business owner to produce daily & monthly reports
                about the business.
              </p>
            </ProjectBox>
            <DevBox leftImg="bulma" centerImg="nodejs" rightImg="tauri" />
          </div>
          <div className="column">
            <motion.img
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              src="images/einvoicing.gif"
              alt=""
            />
          </div>
          <div className="column is-hidden-tablet">
            <ProjectBox title="E-Invoicing System" subtitle="Desktop App">
              <p>
                Implemented a system for a business to create e-invoices,
                analyze revenue, and measure the performance of employees.
              </p>
              <p>
                It's integrated with WhatsApp cloud to run a chatbot that
                enables the business owner to produce daily & monthly reports
                about the business.
              </p>
            </ProjectBox>
            <DevBox leftImg="bulma" centerImg="nodejs" rightImg="tauri" />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Fourth;
