import { motion } from "framer-motion";
import { AnimatedText, variants } from "../components/AnimatedText";

const AnimatedIconText = (props) => (
  <span
    className="icon-text"
    style={{
      overflow: "hidden",
    }}
  >
    <motion.span
      className="icon mr-3"
      variants={variants}
    >
      <i className={`icon-${props.icon} is-size-4`}></i>
    </motion.span>
    <span className="is-align-self-center">
      <AnimatedText text={props.text} type="span" />
    </span>
  </span>
);

export default AnimatedIconText;
