import { motion } from "framer-motion";

const DevBox = (props) => (
  <motion.div
    className="box has-text-centered"
    initial={{ opacity: 0, scale: 0.5 }}
    whileInView={{ opacity: 1, scale: 1 }}
    transition={{ duration: 0.6 }}
    viewport={{ once: true }}
  >
    <p className="heading mb-4">developed using</p>
    <div className="columns is-align-items-center">
      <div className="column">
        <img src={`images/${props.leftImg}_logo.png`} alt="" />
      </div>
      <div className="column">
        <img src={`images/${props.centerImg}_logo.png`} alt="" />
      </div>
      <div className="column">
        <img src={`images/${props.rightImg}_logo.png`} alt="" />
      </div>
    </div>
  </motion.div>
);

export default DevBox;
