import { motion } from "framer-motion";

const draw = {
  hidden: {
    pathLength: 0,
    opacity: 0,
  },
  visible: {
    pathLength: 1,
    opacity: 1,
    transition: {
      duration: 2,
      ease: "easeInOut",
    },
  },
};

const Loading = () => (
  <section className="hero is-fullheight">
    <div className="hero-body">
      <div className="container">
        <div className="columns">
          <div className="column has-text-centered">
            <motion.svg
              width="100"
              height="100"
              viewBox="0 0 293 328"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              initial="hidden"
              animate="visible"
              exit="hidden"
            >
              <motion.path
                d="M5.57472 84.9257L146.5 5.73534L287.425 84.9257V243.074L146.5 322.265L5.57472 243.074V84.9257Z"
                stroke="black"
                variants={draw}
              />
              <motion.line
                x1="192"
                y1="228"
                x2="192"
                y2="103"
                stroke="black"
                variants={draw}
              />
              <motion.line
                x1="99"
                y1="225"
                x2="99"
                y2="100"
                stroke="black"
                variants={draw}
              />
              <motion.line
                x1="100.008"
                y1="103.01"
                x2="191.01"
                y2="225.008"
                stroke="black"
                variants={draw}
              />
            </motion.svg>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Loading;
