import DevBox from "../components/DevBox";
import ProjectBox from "../components/ProjectBox";
import { motion } from "framer-motion";

const Fifth = () => (
  <section className="hero is-fullheight">
    <div className="hero-body">
      <div className="container">
        <div className="columns is-centered is-align-items-center">
          <div className="column">
            <motion.img
              initial={{ opacity: 0, scale: 0.5 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              src="images/nahem.gif"
              alt=""
            />
          </div>
          <div className="column">
            <ProjectBox title="Social Media Platform" subtitle="Web App">
              <p>
                Created a platform for book enthusiasts to rate, review, &
                discover books.
              </p>
              <p>
                It has 100+ features, including a machine learning algorithm to
                produce personalized book recommendations & real-time chatrooms
                to discuss books.
              </p>
            </ProjectBox>
            <DevBox
              leftImg="bootstrap"
              centerImg="flask"
              rightImg="postgresql"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Fifth;
