import { motion } from "framer-motion";
import { AnimatedText } from "../components/AnimatedText";
import AnimatedIconText from "../components/AnimatedIconText";

const First = () => (
  <section className="hero is-fullheight">
    <div className="hero-body">
      <div className="container">
        <div className="columns is-align-items-center">
          <motion.div
            className="column has-text-centered-mobile mb-2"
            initial="hidden"
            animate="visible"
            variants={{
              visible: {
                transition: {
                  staggerChildren: 0.025,
                },
              },
            }}
          >
            <div className="is-size-1-widescreen is-size-3 has-text-weight-semibold">
              <AnimatedText text="Hi. I'm Naif." />
              <AnimatedText text="A Software Engineer." />
            </div>
            <div className="mt-3 mb-6">
              <AnimatedText text="Full-Stack Developer & Solutions Architect." />
            </div>
            <div className="mb-4">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://linkedin.com/in/naif-alsultan"
                className="linkedin"
              >
                <AnimatedIconText
                  icon="linkedin-square"
                  text="linkedin.com/in/naif-alsultan"
                />
              </a>
            </div>
            <div className="mb-4">
              <AnimatedIconText icon="map-marker" text="Riyadh, Saudi Arabia" />
            </div>
          </motion.div>
          <div className="column has-text-right has-text-centered-mobile">
            <motion.img
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              src="images/dev.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default First;
