import { motion } from "framer-motion";

const variants = {
  hidden: {
    y: "200%",
    transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.85 },
  },
  visible: {
    y: 0,
    transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 0.75 },
  },
};

const AnimatedText = (props) => {
  const splitWords = props.text.split(" ");
  const words = [];

  for (const [, item] of splitWords.entries()) {
    words.push(item.split(""));
  }
  
  words.map((word) => {
    return word.push("\u00A0");
  });

  const Tag = props.type ? props.type : "div";

  return (
    <Tag>
      {words.map((word, index) => {
        return (
          <span key={index}>
            {words[index].flat().map((element, index) => {
              return (
                <span
                  style={{
                    overflow: "hidden",
                    display: "inline-block",
                  }}
                  key={index}
                >
                  <motion.span
                    style={{ display: "inline-block" }}
                    variants={variants}
                  >
                    {element}
                  </motion.span>
                </span>
              );
            })}
          </span>
        );
      })}
    </Tag>
  );
};

export { AnimatedText, variants };
